





































import { formatEmailOrPhone } from "@/core";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ForgotPasswordEmail extends Vue {
  emailOrPhone = "";
  errorMsg = "";
  loading = false;
  async next(): Promise<void> {
    this.loading = true;
    try {
      await this.$store.dispatch(
        "forgotPassword/sendSMS",
        this.formattedEmailOrPhone
      );
      this.$router.push({ name: "forgot-password-sms" });
    } catch ({ message }) {
      this.errorMsg = this.$t("General_NetworkError_Message") as string;
    }
    this.loading = false;
  }

  get formattedEmailOrPhone(): string {
    return formatEmailOrPhone(this.emailOrPhone);
  }
}
